/* eslint-disable *///Disable for process
import {
  RenderEngine,
  MusicScoreAB
} from '@xiaoyezi/musicscore-engine'


const colorMap = {
  miss: 'rgb(148, 148, 148)',
  miss2: 'rgb(148, 148, 148)',
  right: 'rgb(38, 183, 122)',
  wrong: 'rgb(254, 92, 90)'
}

export default class ReportSigner {
  constructor(musicScore, noteResultsData = null, scoreData = null, playDataLoader = null, pageType = '') {
    this.noteResultsData = noteResultsData
    this.musicScore = musicScore
    this.scoreData = scoreData
    this.playDataLoader = playDataLoader
    this.pageType = pageType

    this.scoreIdxMap = {}
    this.systemIds = []

    this.noteIdOneMap = []
    this.scoreIdxOneMap = {}

    this.stuckX = 0

    this.currentNote = null
    this.currentText = null
    this.currentAb = null
    this.noteTimer = null
    this.rectTimer = null
    this.fluencys = []
  }

  // 测评报告页曲谱标注数据展示
  initPlayNotation(isLesson, onlyWrong) {
    if (!onlyWrong) {
      this.musicScore.visitNote(note => {
        // note.color = this.noteResultsData.results.length === 0 ? colorMap.right : colorMap.miss
        note.color = colorMap.miss
      })

      this.noteResultsData.results.forEach(v => {
        if (v.scoreNoteId || v.scoreNoteId === 0) {

          if (!this.scoreIdxMap[v.scoreTick]) { // scoreNoteId与scoreTick对应关系
            this.scoreIdxMap[v.scoreTick] = [v.scoreNoteId]
          } else {
            this.scoreIdxMap[v.scoreTick].push(v.scoreNoteId)
          }

          // this.scoreIdxMap[v.scoreTick] = v.scoreNoteId // scoreNoteId与scoreTick对应关系

          const noteIds = this.playDataLoader.rawMidi.getOverLapNoteIds(v.scoreNoteId)
          noteIds.forEach(noteId => {
            let scoreNote = this.musicScore.getNoteById(noteId)
            if (scoreNote && v.pitch !== 'wrong') { // 错音标注以notations为准
              scoreNote.color = colorMap[v.pitch];
            }
          })
        }
      })
      Object.keys(this.scoreIdxMap).forEach(key => {
        if (this.scoreIdxMap[key].length > 1) {
          this.scoreIdxMap[key].sort((a, b) => a - b)
        }
      })
    }

    // 一个音可能会有多个notation，以最后一个notation为准
    this.fluencys = []
    let notations = []
    let tempFluencys = []
    const arr = onlyWrong ? ['early', 'late', 'wrong', 'pause'] : ['early', 'late', 'miss2', 'wrong', 'pause']
    this.noteResultsData.notations.forEach(v => {
      if (arr.indexOf(v.type) !== -1) {
        notations.push(v)
      }
      if (v.type === 'fluency') {
        tempFluencys.push(v)
      }
    })

    let x = {
      early: -2,
      late: 2
    }
    // 展示卡顿音符label
    notations.forEach(v => {
      if (v.scoreNoteId) {
        let note = this.musicScore.getNoteById(v.scoreNoteId);
        if (note) {
          note._notation = v;
          if (['early', 'late'].indexOf(v.type) !== -1) {
            let noteClone = note.clone();
            noteClone.color = 'rgba(255, 0, 0, 0.5)';
            noteClone.position = [noteClone.position[0] + x[v.type], noteClone.position[1]];
          } else if (v.type === 'pause') {
            this._showStuckLabel(note);
          } else {
            note.color = colorMap[v.type];
          }
        }
      }
    })
    if (isLesson) return
    // 高亮不熟练区域 lesson不需要
    let fluencyIndex = 0
    tempFluencys.forEach((v) => {
      if (v.startScoreTick !== null && v.endScoreTick !== null) {
        if (JSON.stringify(this.scoreIdxMap) !== '{}') {
          this._getNewSystemIds(v)
          const useTick = Boolean(v.startScoreTick !== v.endScoreTick)
          let startNoteId = v.startScoreNote || (useTick && this.scoreIdxMap[v.startScoreTick] ? this.scoreIdxMap[v.startScoreTick][0] : null);
          let endNoteId = v.endScoreNote || (useTick && this.scoreIdxMap[v.endScoreTick] ? this.scoreIdxMap[v.endScoreTick][this.scoreIdxMap[v.endScoreTick].length - 1] : null);
          if ((startNoteId || startNoteId === 0) && (endNoteId || endNoteId === 0)) {
            let note0 = this.musicScore.getNoteById(startNoteId);
            let note1 = this.musicScore.getNoteById(endNoteId);
            if (note0 && note1) {
              this.createFluencys(note0, note1, v, fluencyIndex)
              this.fluencys.push(v)
              fluencyIndex++
            }
          }
        }
      }
    })
    console.log('reportSinger->this.fluencys:', this.fluencys)
  }

  lessonInitPlayNotation(noteResultsData, musicScore, onlyWrong) {
    if (musicScore) this.musicScore = musicScore
    if (!onlyWrong) {
      this.musicScore.visitNote(note => {
        note.color = colorMap.miss
      })
      noteResultsData.results.forEach(v => {
        if (v.scoreNoteId || v.scoreNoteId === 0) {

          this.scoreIdxMap[v.scoreTick] = v.scoreNoteId // scoreNoteId与scoreTick对应关系

          const noteIds = this.playDataLoader.rawMidi.getOverLapNoteIds(v.scoreNoteId)
          noteIds.forEach(noteId => {
            let scoreNote = this.musicScore.getNoteById(noteId)
            if (scoreNote) {
              scoreNote.color = colorMap[v.pitch];
            }
          })
        }
      })
    }
    // 一个音可能会有多个notation，以最后一个notation为准
    let fluencys = []
    let notations = []
    const arr = onlyWrong ? ['early', 'late', 'wrong', 'pause'] : ['early', 'late', 'miss2', 'wrong', 'pause']
    noteResultsData.notations.forEach(v => {
      if (arr.indexOf(v.type) !== -1) {
        notations.push(v)
      }
      if (v.type === 'fluency') {
        fluencys.push(v)
      }
    })

    let x = {
      early: -2,
      late: 2
    }
    // 展示卡顿音符label
    notations.forEach(v => {
      if (v.scoreNoteId) {
        let note = this.musicScore.getNoteById(v.scoreNoteId);
        if (note) {
          note._notation = v;
          if (['early', 'late'].indexOf(v.type) !== -1) {
            let noteClone = note.clone();
            noteClone.color = 'rgba(255, 0, 0, 0.5)';
            noteClone.position = [noteClone.position[0] + x[v.type], noteClone.position[1]];
          } else if (v.type === 'pause') {
            this._showStuckLabel(note);
          } else {
            note.color = colorMap[v.type];
          }
        }
      }
    })
  }
  findABRealPart(startNote, endNote, notation, systemLength, index) {
    // 当高亮区域有反复时，通过midi的tick顺序来找实际区域
    if (!this.scoreData.midi.notes || !this.scoreData.midi.notes[0].length) return []
    const allMidi = this.scoreData.midi.notes[0].reduce((acc, cur) => acc.concat(cur), []).sort((a, b) => a.tick - b.tick)
    const result = []
    const threshold = Math.ceil(Math.log(allMidi.length * 1.5))
    let startIndex = 0, endIndex = 0
    let part = []
    for (let i = 0; i < allMidi.length; i++) {
      if (startNote.id === allMidi[i].score.noteId && allMidi[i].tick === notation.startScoreTick) {
        console.log('!', allMidi[i].score.noteId, allMidi[i].tick)
        startIndex = i
        continue
      }
      if (endNote.id === allMidi[i].score.noteId && allMidi[i].tick === notation.endScoreTick) {
        console.log('!', allMidi[i].score.noteId, allMidi[i].tick)
        endIndex = i
        break
      }
      // console.log(allMidi[i].score.noteId, allMidi[i].tick, allMidi[i], allMidi.length)
    }
    part = allMidi.slice(startIndex, endIndex + 1)
    if (startIndex !== endIndex && part.length > 1) {
      let needJudgeSystem = true
      // 起始结束在同一行，不需要判断分行
      if (systemLength === 1 && part[0].score.systemId === part[part.length - 1].score.systemId) needJudgeSystem = false
      for (let i = 0; i < part.length; i++) {
        let needJudgeSystemItem = needJudgeSystem
        if (i === 0) {
          result.push(part[i].score.noteId)
          continue
        }
        if (needJudgeSystemItem) {
          needJudgeSystemItem = Boolean(part[i - 1].score.systemId !== part[i].score.systemId)
        } else {
          needJudgeSystemItem = true
        }
        if (part[i - 1].score.noteId - part[i].score.noteId > threshold + 1 && needJudgeSystemItem) {
          // 因为midi note双手都有，noteId就有后面小的情况
          // 既然是反复了，那前后差值总得大于5吧。。
          result.push(part[i - 1].score.noteId)
          result.push(part[i].score.noteId)
        }
        if (part[i].score.noteId - part[i - 1].score.noteId > threshold + 2 && needJudgeSystemItem) {
          // 可能有在2+房子里的情况，要撇过1房子
          // 也给先整个阈值4，出bug再调, 阈值调整为+2
          result.push(part[i - 1].score.noteId)
          result.push(part[i].score.noteId)
        }
      }
      result.push(part[part.length - 1].score.noteId)
      console.log('高亮区域有反复findABRealPart->threshold', systemLength, index, threshold, result)
      return result
    } else {
      return 0
    }
  }
  // 高亮不熟练区域
  createFluencys(startNote, endNote, notation = null, index = -1, bgColor = 'rgba(255, 169, 169, .25)') {
    this._addFluencysRect(startNote.system, startNote.position[0] - 4)
    this._addFluencysRect(endNote.system, endNote.position[0] + 8)
    this._addRectBorder(startNote.system, startNote.position[0] - 4)
    this._addRectBorder(endNote.system, endNote.position[0])
    let ab = new MusicScoreAB();
    if (startNote.system.id <= endNote.system.id && new Set(this.systemIds).size === this.systemIds.length && (startNote.id < endNote.id)) {
      ab.attach(this.musicScore, startNote, endNote, bgColor, -1);
    } else { // 高亮区域有反复
      if (notation) {
        const ABSlice = this.findABRealPart(startNote, endNote, notation, this.systemIds.length, index)
        if (ABSlice) {
          for (let i = 0; i < ABSlice.length; i += 2) {
            ab.attach(this.musicScore, this.musicScore.getNoteById(ABSlice[i]), this.musicScore.getNoteById(ABSlice[i + 1]), bgColor, -1);
          }
        } else {
          ab.attachToSystems(this.musicScore, this.systemIds, startNote, endNote, bgColor, -1);
        }
      } else {
        ab.attachToSystems(this.musicScore, this.systemIds, startNote, endNote, bgColor, -1);
      }
    }
    if (index !== -1) {
      ab.on('click', () => {
        this._resetLabel();
        if (this.pageType === 'report') {
          ab.setBtnLabel({
            text: '练这段>',
            leftX: 10,
            top: -23,
            bgColor: 'rgba(48, 202, 137, 1)',
            fontSize: 10,
            color: 'rgb(255, 255, 255)',
            borderRx: 10,
            borderRy: 10,
            zIndex: 10000,
            borderHorizontalPadding: 10,
            borderVerticalPadding: 4,
          })
        } else {
          ab.setLabel('不熟练');
          // ab.setBtnLabel('看 >', 30);
        }
        ab.labelVisible = true;
        this.currentAb = ab;
      })
    }
  }

  // 高亮不熟练区域by tick
  _createFluencysByTick(startTick, endTick) {
    let ab = new MusicScoreAB()
    let { startNote, endNote } = ab.attachByTick(this.musicScore, this.scoreData.midi.notes[0], this.scoreData.config.repeats, startTick, endTick, 'rgba(255, 169, 169, .25)', -1)
    this._addFluencysRect(startNote.system, startNote.position[0] - 4)
    this._addFluencysRect(endNote.system, endNote.position[0] + 8)
    this._addRectBorder(startNote.system, startNote.position[0] - 4)
    this._addRectBorder(endNote.system, endNote.position[0])
  }

  // 展示卡顿音符label
  _showStuckLabel(note) {
    let x = note.position[0];
    if (Math.abs(x - this.stuckX) < 10) { // 去重
      return
    }
    let text = RenderEngine.render.MusicScoreTextRender.create(note.system, {});
    note.system.addChild(text, 0);
    text.position = [x, -3];
    text.bgColor = 'rgba(254, 92, 90, 1)';
    text.borderHorizontalPadding = 3;
    text.borderVerticalPadding = 1.6;
    text.borderRx = '50%';
    text.borderRy = '50%';
    text.text = '卡';
    text.color = 'rgb(255, 255, 255)';
    text.fontSize = 8;

    this.stuckX = x;
  }

  // 不熟练区域边框
  _addFluencysRect(system, x) {
    let view = RenderEngine.render.MusicScoreRectRender.create(null);
    view.size = [4, system.size[1] + 20];
    view.position = [x, -10];
    view.color = 'rgba(255, 151, 151, 1)';
    view.visible = true;
    view.overflow = true;
    system.addChild(view, 10000);
  }

  // 不熟练区域边框方括号
  _addRectBorder(system, x) {
    let left = RenderEngine.render.MusicScoreRectRender.create(null);
    left.size = [12, 4];
    left.position = [x, -14];
    left.color = 'rgba(255, 151, 151, 1)';
    left.visible = true;
    system.addChild(left, 10000);

    let right = RenderEngine.render.MusicScoreRectRender.create(null);
    right.size = [12, 4];
    right.position = [x, system.size[1] + 10];
    right.color = 'rgba(255, 151, 151, 1)';
    right.visible = true;
    system.addChild(right, 10000);
  }

  // 展示音符label
  showNoteLabel(note, name) {
    this._resetLabel()

    let text = RenderEngine.render.MusicScoreTextRender.create(note.system, {});
    let x = name === '节奏问题' ? note.position[0] - 15 : note.position[0] - 4;
    text._zIndex = 10000;
    note.system.addChild(text, text._zIndex);
    text.position = [x, note.staff.position[1] - 20];
    text.bgColor = 'rgba(253, 40, 83, 0.1)';
    text.borderHorizontalPadding = 10;
    text.borderVerticalPadding = 3;
    text.borderRx = 5;
    text.borderRy = 5;
    text.text = name;
    text.color = 'rgb(253, 40, 83)';
    text.fontSize = 10;

    this.currentNote = note;
    this.currentText = text;
    this.noteTimer = setTimeout(() => {
      this._removeNoteLabel(note);
      this.currentNote = null;
    }, 3000)
  }

  _removeNoteLabel(note) {
    note.system.view.removeChild(this.currentText)
  }

  _resetLabel() {
    if (this.noteTimer) {
      clearTimeout(this.noteTimer);
      this.noteTimer = null;
    }
    if (this.rectTimer) {
      clearTimeout(this.rectTimer);
      this.rectTimer = null;
    }
    if (this.currentNote) {
      this._removeNoteLabel(this.currentNote)
      this.currentNote = null
    }
    if (this.currentAb) {
      this.currentAb.labelVisible = false;
      this.currentAb = null;
    }
  }
  // 获取systemIds
  _getNewSystemIds(v) {
    this.systemIds = []
    for (let i = v.startScoreTick; i <= v.endScoreTick; i++) {
      if (!this.scoreIdxMap[i] || !this.scoreIdxMap[i][0]) {
        continue
      }
      let scoreNoteId = this.scoreIdxMap[i][0];
      let note = this.musicScore.getNoteById(scoreNoteId);
      if (this.systemIds.length === 0) {
        this.systemIds.push(note.system.id)
      } else {
        let index = this.systemIds.length - 1
        if (note.system.id !== this.systemIds[index]) {
          this.systemIds.push(note.system.id)
        }
      }
    }
  }

  // 获取systemIds(适配历史评测数据，one文件)
  _getSystemIds(v) {
    this.systemIds = []
    for (let i = v.startScoreNote; i <= v.endScoreNote; i++) {
      let scoreNote = this.noteIdOneMap[this.scoreIdxOneMap[i]];
      if (!scoreNote) {
        continue
      }
      let note = this.musicScore.getNoteById(scoreNote.noteId);
      if (this.systemIds.length === 0) {
        this.systemIds.push(note.system.id)
      } else {
        let index = this.systemIds.length - 1
        if (note.system.id !== this.systemIds[index]) {
          this.systemIds.push(note.system.id)
        }
      }
    }
  }

  // 评测报告页曲谱标注数据展示(适配历史评测数据，one文件)
  initPlayNotationInMusicScore(noteIdMap) {
    this.noteIdOneMap = noteIdMap

    this.musicScore.visitNote(note => {
      note.color = 'rgb(148, 148, 148)';
    });

    this.noteResultsData.results.forEach(v => {
      if (['miss', 'right', 'wrong'].indexOf(v.pitch) !== -1) {
        if (v.scoreNote) {
          this.scoreIdxOneMap[v.scoreNote._index] = v.scoreNote._score
          let scoreNote = this.noteIdOneMap[v.scoreNote._score];
          if (scoreNote) {
            let note = this.musicScore.getNoteById(scoreNote.noteId);
            if (note) {
              note.color = colorMap[v.pitch];
            }
          }
        }
      }
    });
    let x = {
      early: -2,
      late: 2
    }

    // 一个音可能会有多个notation，以最后一个notation为准
    let fluencys = []
    let notations = {}
    this.noteResultsData.notations.forEach(v => {
      if (['early', 'late', 'miss2', 'wrong', 'pause'].indexOf(v.type) !== -1) {
        notations[v.scoreNote] = v
      }
      if (v.type === 'fluency') {
        fluencys.push(v)
      }
    })
    // 高亮不熟练区域
    fluencys.forEach(v => {
      if ((v.startScoreNote || v.startScoreNote === 0) && v.endScoreNote) {
        this._getSystemIds(v)
        let startNote = this.noteIdOneMap[this.scoreIdxOneMap[v.startScoreNote]];
        let endNote = this.noteIdOneMap[this.scoreIdxOneMap[v.endScoreNote]];
        if (startNote && endNote) {
          let note0 = this.musicScore.getNoteById(startNote.noteId);
          let note1 = this.musicScore.getNoteById(endNote.noteId);
          if (note0 && note1) {
            this.createFluencys(note0, note1, v)
          }
        }
      }
    })

    Object.values(notations).forEach(v => {
      if (v.scoreNote) {
        let scoreNote = this.noteIdOneMap[this.scoreIdxOneMap[v.scoreNote]];
        if (scoreNote) {
          let note = this.musicScore.getNoteById(scoreNote.noteId);
          if (note) {
            note._notation = v;
            if (['early', 'late'].indexOf(v.type) !== -1) {
              let noteClone = note.clone();
              noteClone.color = 'rgba(255, 0, 0, 0.5)';
              noteClone.position = [noteClone.position[0] + x[v.type], noteClone.position[1]];
            } else if (v.type === 'pause') {
              this._showStuckLabel(note);
            } else {
              note.color = colorMap[v.type];
            }
          }
        }
      }
    });
  }
}
